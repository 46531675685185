<template>
<div>
    <!-- 通道管理》通道配置》通道详情》通道增加或修改 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>{{cur_title}}</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                <el-form  :model="form_data" label-width="138px" style="margin-bottom: 12px;">
                    <el-form-item label="通道名称">
                        <el-input v-model="form_data.channel_name" placeholder="请输入通道名称" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="可发运营商">
                        <el-checkbox-group v-model="form_data.oper_array" size="mini">
                            <el-checkbox  label="0">移动</el-checkbox>
                            <el-checkbox  label="1">联通</el-checkbox>
                            <el-checkbox  label="2">电信</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>


                    <el-form-item v-show="form_data.oper_array.indexOf('0') > -1" label="移动可发省份">
                        <el-checkbox-group v-model="form_data.cm_province_array" size="mini">
                            <template v-for="item in provList" >
                                <el-checkbox :key="item.id" :label="item.id+''" :disabled="cm_prov_city_select_map.has(item.id+'')" :indeterminate="cm_prov_city_select_map.has(item.id+'')"
                                    @change="provinceChange($event,0, item.id)" style="margin-right: 0px;"><br></el-checkbox>
                                
                                <div :key="item.id+1000" class="cursor" style="font-size:14px;display: inline-block;position: relative;margin-right: 20px;"
                                      @click="selectCity(item.id,item.province,0)">{{item.province}}
                                </div>
                            </template>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="移动可发地市" v-show="cm_prov_city_select_map.size >  0 && form_data.oper_array.indexOf('0') > -1 " >
                         <template v-for="(value, key) in cm_prov_city_select_map" >
                            <div :key="key">{{value[1].prov_name+"："+value[1].citys}}</div>
                        </template>
                    </el-form-item>


                    <el-form-item v-show="form_data.oper_array.indexOf('1') > -1 " label="联通可发省份">
                        <el-checkbox-group v-model="form_data.cu_province_array" size="mini">
                            <template v-for="item in provList" >
                                <el-checkbox :key="item.id" :label="item.id+''" :disabled="cu_prov_city_select_map.has(item.id+'')" :indeterminate="cu_prov_city_select_map.has(item.id+'')"
                                   @change="provinceChange($event,1, item.id)" style="margin-right: 0px;"><br></el-checkbox>
                                
                                <div :key="item.id+1000" class="cursor" style="font-size:14px;display: inline-block;position: relative;margin-right: 20px;"
                                      @click="selectCity(item.id,item.province,1)">{{item.province}}
                                </div>
                            </template>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="联通可发地市" v-show="cu_prov_city_select_map.size >  0  && form_data.oper_array.indexOf('1') > -1" >
                         <template v-for="(value, key) in cu_prov_city_select_map" >
                            <div :key="key">{{value[1].prov_name+"："+value[1].citys}}</div>
                        </template>
                    </el-form-item>


                    <el-form-item v-show="form_data.oper_array.indexOf('2') > -1" label="电信可发省份">
                        <el-checkbox-group v-model="form_data.ct_province_array" size="mini">
                            <template v-for="item in provList" >
                                <el-checkbox :key="item.id" :label="item.id+''" :disabled="ct_prov_city_select_map.has(item.id+'')" :indeterminate="ct_prov_city_select_map.has(item.id+'')"
                                     @change="provinceChange($event,2, item.id)" style="margin-right: 0px;"><br></el-checkbox>
                                
                                <div :key="item.id+1000" class="cursor" style="font-size:14px;display: inline-block;position: relative;margin-right: 20px;"
                                      @click="selectCity(item.id,item.province,2)">{{item.province}}
                                </div>
                            </template>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="电信可发地市" v-show="ct_prov_city_select_map.size >  0  && form_data.oper_array.indexOf('2') > -1 " >
                         <template v-for="(value, key) in ct_prov_city_select_map" >
                            <div :key="key">{{value[1].prov_name+"："+value[1].citys}}</div>
                        </template>
                    </el-form-item>


                    <el-form-item label="通道类型">
                        <el-radio-group v-model="form_data.channel_style" size="mini">
                            <el-radio label="0">短信通道</el-radio>
                            <el-radio label="1">语音通道</el-radio>
                            <el-radio label="2">多媒体短信通道</el-radio>
                            <el-radio label="3">固话通道</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-show="form_data.channel_style == '1'" label="语音条数价格">
                        <el-input v-model="form_data.channel_voice_str"
                            type="textarea" :rows="4"
                         disabled placeholder="请按右边按钮进行设置" size="mini" style="width:70%"></el-input>
                        <el-button type="text" size="mini" style="margin-left:15px;" @click="voice_set()">设置</el-button>
                    </el-form-item>

                    <el-form-item label="服务号码">
                        <el-input v-model="form_data.specialnum" placeholder="请输入服务号码" size="mini" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
                    </el-form-item>

                    <el-form-item label="短信数据统计">
                        <el-radio-group v-model="form_data.alarm" size="mini">
                            <el-radio label="0">不预警</el-radio>
                            <el-radio label="1">预警</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="签名位置">
                        <el-select v-model="form_data.sign_position" size="mini" style="width:100%"> 
                            <el-option label="强制前置" value="0"></el-option>
                            <el-option label="强制后置" value="1"></el-option>
                            <el-option label="不管签名位置" value="2"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="签名库">
                        <el-select v-model="form_data.sign_library_id" filterable size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in signLibraryList"
                                :key="item.sign_library_id"
                                :label="item.sign_name"
                                :value="item.sign_library_id+''"
                                >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="签名扩展类型" v-show="form_data.sign_library_id != '0'">
                        <el-select v-model="form_data.sign_ext_onoff"  size="mini" style="width:100%"> 
                            <el-option label="使用签名库扩展" value="0"></el-option>
                            <el-option label="使用签名库+客户扩展" value="1"></el-option>
                            <el-option label="使用客户扩展" value="2"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="后报备签名" v-show="form_data.sign_library_id != '0'">
                        <el-radio-group v-model="form_data.sign_after" size="mini">
                            <el-radio label="0">允许</el-radio>
                            <el-radio label="1">不允许</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="通道状态">
                        <el-radio-group v-model="form_data.onoff" size="mini">
                            <el-radio label="1">打开</el-radio>
                            <el-radio label="0">关闭</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="是否支持携号转网">
                        <el-radio-group v-model="form_data.change_oper_onoff" size="mini">
                            <el-radio label="1">支持</el-radio>
                            <el-radio label="0">不支持</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="实时空号过滤">
                        <el-radio-group v-model="form_data.phone_check" size="mini">
                            <el-radio label="0">打开</el-radio>
                            <el-radio label="1">关闭</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="是否信任">
                        <el-radio-group v-model="form_data.trust_onoff" size="mini">
                            <el-radio label="1">信任</el-radio>
                            <el-radio label="0">不信任</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="下发短信类型">
                        <el-checkbox-group v-model="form_data.send_type_array" size="mini">
                            <el-checkbox  label="1">验证码</el-checkbox>
                            <el-checkbox  label="2">行业</el-checkbox>
                            <el-checkbox  label="3">营销</el-checkbox>
                            <el-checkbox  label="4">四类</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>

                    <el-form-item label="是否允许带链接">
                        <el-radio-group v-model="form_data.link_is" size="mini">
                            <el-radio label="0">允许</el-radio>
                            <el-radio label="1">不允许</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="带链接可发级别" v-show="form_data.link_is == '0'">
                        <el-radio-group v-model="form_data.link_trust_level" size="mini">
                             <el-radio label="1">可发不信任的客户</el-radio>
                            <el-radio label="0">可发信任的客户</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="是否支持长短信">
                        <el-radio-group v-model="form_data.isLong" size="mini">
                            <el-radio label="0">支持</el-radio>
                            <el-radio label="1">不支持</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="是否支持上行">
                        <el-radio-group v-model="form_data.isMo" size="mini">
                            <el-radio label="0">支持</el-radio>
                            <el-radio label="1">不支持</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="支持的最大扩展位数">
                        <el-input v-model="form_data.ext_max" placeholder="0表示未知扩展码位数" size="mini" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                    </el-form-item>

                    <el-form-item label="支持的特殊标签">
                        <el-select v-model="form_data.special_label_array" placeholder="选填" filterable multiple size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in special_labelList"
                                :key="item.name"
                                :label="item.name"
                                :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="特殊标签的作用范围" v-show="form_data.special_label_array.length > 0">
                        <el-radio-group v-model="form_data.special_label_type" size="mini">
                            <el-radio label="0">可发此特殊标签</el-radio>
                            <el-radio label="1">仅能发此特殊标签</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="供应商类别">
                        <el-radio-group v-model="form_data.supplier_type" size="mini">
                            <el-radio label="0">直连</el-radio>
                            <el-radio label="1">准直连</el-radio>
                            <el-radio label="2">三方</el-radio>
                            <el-radio label="3">厂商</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="厂商类别" v-if="form_data.supplier_type == '3'">
                        <el-radio-group v-model="form_data.firm_type" size="mini">
                            <el-radio label="0">小米</el-radio>
                            <el-radio label="1">oppo</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="码号类别">
                        <el-radio-group v-model="form_data.number_type" size="mini">
                            <el-radio label="0">固定码号</el-radio>
                            <el-radio label="1">通道组</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="通道下发地区类型">
                        <el-radio-group v-model="form_data.all_or_prov" size="mini">
                            <el-radio label="0">全国</el-radio>
                            <el-radio label="1">省网</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="是否允许带联系方式">
                        <el-radio-group v-model="form_data.is_connect" size="mini">
                            <el-radio label="0">允许</el-radio>
                            <el-radio label="1">不允许</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="是否支持纯英文">
                        <el-radio-group v-model="form_data.pure_english_onoff" size="mini">
                            <el-radio label="0">支持</el-radio>
                            <el-radio label="1">不支持</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="是否是自选通道">
                        <el-radio-group v-model="form_data.is_own" size="mini">
                            <el-radio label="0">是</el-radio>
                            <el-radio label="1">不是</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="活跃性开关">
                        <el-radio-group v-model="form_data.active_onoff" size="mini">
                            <el-radio label="0">打开</el-radio>
                            <el-radio label="1">关闭</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="预警策略">
                        <el-select v-model="form_data.warning_id" filterable size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in warningList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id+''">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="付费类型">
                        <el-radio-group v-model="form_data.channel_fee_type" size="mini">
                            <el-radio label="1">后付</el-radio>
                            <el-radio label="0">预付</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="结算方式" v-if="form_data.channel_fee_type == '1'">
                        <el-radio-group v-model="form_data.pay_style" size="mini">
                            <el-radio label="2">日结</el-radio>
                            <el-radio label="3">周结</el-radio>
                            <el-radio label="4">半月结</el-radio>
                            <el-radio label="5">月结</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="通道余额" v-show="form_data.channel_fee_type == '0'">
                        <el-input v-model="form_data.channel_fee" placeholder="请输入通道余额" size="mini" oninput="value=value.replace(/[^\d.]/g,'')" style="vertical-align: middle;">
                            <template slot="append">元</template>
                        </el-input>
                    </el-form-item>


                    <el-form-item label="付款公司">
                        <el-select v-model="form_data.payment_company_id" filterable size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in selfCompanyList"
                                :key="item.id"
                                :label="item.company_name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="收款公司">
                        <el-select v-model="form_data.collection_company_id" filterable size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in channelCompanyList"
                                :key="item.id"
                                :label="item.company_name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="启动路径">
                        <el-input v-model="form_data.path_start" placeholder="选填" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="关闭路径">
                        <el-input v-model="form_data.path_shutdown" placeholder="选填" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="网关ip">
                        <el-input v-model="form_data.server_ip" placeholder="请输入网关ip" size="mini" ></el-input>
                    </el-form-item>

                    <el-form-item label="网关开关">
                        <el-radio-group v-model="form_data.net_work_onoff" size="mini">
                            <el-radio label="1">打开</el-radio>
                            <el-radio label="0">手动关闭</el-radio>
                            <el-radio label="2">自动关闭</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="频次限制">
                        <el-input v-model="form_data.frequency" placeholder="选填" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="发送速度">
                        <el-input v-model="form_data.send_speed" placeholder="请输入发送速度" size="mini" oninput="value=value.replace(/[^\d]/g,'')" style="vertical-align: middle;">
                            <template slot="append">条/秒</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="可发送时段-验证码">
                        <el-input v-model="form_data.send_start_hh_yzm" placeholder="" style="width:50px;" size="mini" @change="parseInt(form_data.send_start_hh_yzm) >= 24 ? form_data.send_start_mm_yzm='00' : '' " oninput="value=value.replace(/[^\d]/g,'')">
                        </el-input>
                        点
                        <el-select v-model="form_data.send_start_mm_yzm" :disabled="parseInt(form_data.send_start_hh_yzm) >= 24" size="mini" style="width:70px"> 
                            <el-option label="整" value="00"></el-option>
                            <el-option label="半" value="30"></el-option>
                        </el-select>
                        到
                        <el-input v-model="form_data.send_end_hh_yzm" placeholder="" style="width:50px;" size="mini" @change="parseInt(form_data.send_end_hh_yzm) >= 24 ? form_data.send_end_mm_yzm='00' : '' " oninput="value=value.replace(/[^\d]/g,'')">
                        </el-input>
                        点
                        <el-select v-model="form_data.send_end_mm_yzm" :disabled="parseInt(form_data.send_end_hh_yzm) >= 24" size="mini" style="width:70px"> 
                            <el-option label="整" value="00"></el-option>
                            <el-option label="半" value="30"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="可发送时段-行业">
                        <el-input v-model="form_data.send_start_hh_hy" placeholder="" style="width:50px;" size="mini" @change="parseInt(form_data.send_start_hh_hy) >= 24 ? form_data.send_start_mm_hy='00' : '' " oninput="value=value.replace(/[^\d]/g,'')">
                        </el-input>
                        点
                        <el-select v-model="form_data.send_start_mm_hy" :disabled="parseInt(form_data.send_start_hh_hy) >= 24" size="mini" style="width:70px"> 
                            <el-option label="整" value="00"></el-option>
                            <el-option label="半" value="30"></el-option>
                        </el-select>
                        到
                        <el-input v-model="form_data.send_end_hh_hy" placeholder="" style="width:50px;" size="mini" @change="parseInt(form_data.send_end_hh_hy) >= 24 ? form_data.send_end_mm_hy='00' : '' " oninput="value=value.replace(/[^\d]/g,'')">
                        </el-input>
                        点
                        <el-select v-model="form_data.send_end_mm_hy" :disabled="parseInt(form_data.send_end_hh_hy) >= 24" size="mini" style="width:70px"> 
                            <el-option label="整" value="00"></el-option>
                            <el-option label="半" value="30"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="可发送时段-营销">
                        <el-input v-model="form_data.send_start_hh_yx" placeholder="" style="width:50px;" size="mini" @change="parseInt(form_data.send_start_hh_yx) >= 24 ? form_data.send_start_mm_yx='00' : '' " oninput="value=value.replace(/[^\d]/g,'')">
                        </el-input>
                        点
                        <el-select v-model="form_data.send_start_mm_yx" :disabled="parseInt(form_data.send_start_hh_yx) >= 24" size="mini" style="width:70px"> 
                            <el-option label="整" value="00"></el-option>
                            <el-option label="半" value="30"></el-option>
                        </el-select>
                        到
                        <el-input v-model="form_data.send_end_hh_yx" placeholder="" style="width:50px;" size="mini" @change="parseInt(form_data.send_end_hh_yx) >= 24 ? form_data.send_end_mm_yx='00' : '' " oninput="value=value.replace(/[^\d]/g,'')">
                        </el-input>
                        点
                        <el-select v-model="form_data.send_end_mm_yx" :disabled="parseInt(form_data.send_end_hh_yx) >= 24" size="mini" style="width:70px"> 
                            <el-option label="整" value="00"></el-option>
                            <el-option label="半" value="30"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="可发送时段-四类">
                        <el-input v-model="form_data.send_start_hh_sl" placeholder="" style="width:50px;" size="mini" @change="parseInt(form_data.send_start_hh_sl) >= 24 ? form_data.send_start_mm_sl='00' : '' " oninput="value=value.replace(/[^\d]/g,'')">
                        </el-input>
                        点
                        <el-select v-model="form_data.send_start_mm_sl" :disabled="parseInt(form_data.send_start_hh_sl) >= 24" size="mini" style="width:70px"> 
                            <el-option label="整" value="00"></el-option>
                            <el-option label="半" value="30"></el-option>
                        </el-select>
                        到
                        <el-input v-model="form_data.send_end_hh_sl" placeholder="" style="width:50px;" size="mini" @change="parseInt(form_data.send_end_hh_sl) >= 24 ? form_data.send_end_mm_sl='00' : '' " oninput="value=value.replace(/[^\d]/g,'')">
                        </el-input>
                        点
                        <el-select v-model="form_data.send_end_mm_sl" :disabled="parseInt(form_data.send_end_hh_sl) >= 24" size="mini" style="width:70px"> 
                            <el-option label="整" value="00"></el-option>
                            <el-option label="半" value="30"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="备注">
                        <el-input v-model="form_data.content" placeholder="选填" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="协议类型">
                        <el-select v-model="form_data.agreement"  size="mini" style="width:100%"> 
                            <el-option label="CMPP2.0" value="101"></el-option>
                            <el-option label="CMPP3.0" value="102"></el-option>
                            <el-option label="SGIP" value="104"></el-option>
                            <el-option label="SMGP" value="105"></el-option>
                            <el-option label="HTTP" value="1"></el-option>
                            <el-option label="无协议" value="0"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="用户名">
                        <el-input v-model="form_data.net_work_act" placeholder="请输入用户名" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="密码">
                        <el-input v-model="form_data.net_work_pwd" placeholder="请输入密码" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="userId" v-if="form_data.agreement == '1'">
                        <el-input v-model="form_data.userId" placeholder="请输入userId" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="并发数" v-show="form_data.agreement != '1' && form_data.agreement != '0'">
                        <el-input v-model="form_data.concurrent" placeholder="请输入并发数" size="mini" oninput="value=value.replace(/[^\d]/g,'')">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="扩展码截取顺序">
                        <el-radio-group v-model="form_data.ext_order" size="mini">
                            <el-radio label="0">从前往后</el-radio>
                            <el-radio label="1">从后往前</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="扩展码截取位数">
                        <el-input v-model="form_data.ext_num" placeholder="请输入扩展码截取位数" size="mini" oninput="value=value.replace(/[^\d]/g,'')">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="去签名">
                        <el-radio-group v-model="form_data.delSign" size="mini">
                            <el-radio label="0">不需要</el-radio>
                            <el-radio label="1">需要</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="日志输出">
                        <el-radio-group v-model="form_data.log_Onoff" size="mini">
                            <el-radio label="0">不需要</el-radio>
                            <el-radio label="1">需要</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="链接ip" v-show="form_data.agreement != '0' && form_data.agreement != '1'"
                        :rules="[{required: true}]">
                        <el-input v-model="form_data.Gateway_IP" placeholder="请输入链接ip" size="mini" oninput="value=value.replace(/[^\d.]/g,'')">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="企业代码msg_src" v-show="form_data.agreement != '0' && form_data.agreement != '1'"
                        :rules="[{required: true}]">
                        <el-input v-model="form_data.msg_Src" placeholder="请输入企业代码" size="mini">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="链接端口" v-show="form_data.agreement != '0' && form_data.agreement != '1'"
                        :rules="[{required: true}]">
                        <el-input v-model="form_data.Gateway_Port" placeholder="请输入连接端口" size="mini" oninput="value=value.replace(/[^\d]/g,'')">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="发送缓冲" v-show="form_data.agreement != '0' && form_data.agreement != '1'"
                        :rules="[{required: true}]">
                        <el-input v-model="form_data.buffer_time" placeholder="请输入发送缓冲" size="mini"  style="vertical-align: middle;"
                          oninput="value=value.replace(/[^\d]/g,'')">
                             <template slot="append">毫秒/条</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="心跳包间隔发送" v-show="form_data.agreement != '0' && form_data.agreement != '1'"
                        :rules="[{required: true}]">
                        <el-input v-model="form_data.heartbeat_interval" placeholder="请输入心跳包间隔发送时间" size="mini"  style="vertical-align: middle;"
                          oninput="value=value.replace(/[^\d]/g,'')">
                             <template slot="append">秒</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="链接超时阈值" v-show="form_data.agreement != '0' && form_data.agreement != '1'"
                        :rules="[{required: true}]">
                        <el-input v-model="form_data.reconnect_interval" placeholder="请输入链接运营商超时阈值" size="mini"  style="vertical-align: middle;"
                          oninput="value=value.replace(/[^\d]/g,'')">
                             <template slot="append">秒</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="心跳包等待最长" v-show="form_data.agreement != '0' && form_data.agreement != '1'"
                        :rules="[{required: true}]">
                        <el-input v-model="form_data.heartbeat_noresponseout" placeholder="请输入心跳包等待最长时间" size="mini"  style="vertical-align: middle;"
                          oninput="value=value.replace(/[^\d]/g,'')">
                             <template slot="append">秒</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="业务类型service_Id" v-show="form_data.agreement != '0' && form_data.agreement != '1'">
                        <el-input v-model="form_data.Service_Id" placeholder="选填" size="mini"  >
                        </el-input>
                    </el-form-item>

                    <el-form-item label="扩展码(src_id)" v-show="form_data.agreement != '0' && form_data.agreement != '1'">
                        <el-input v-model="form_data.SRC_ID" placeholder="选填" size="mini"  >
                        </el-input>
                    </el-form-item>

                    <el-form-item label="本地监听端口" v-show="form_data.agreement == '104'"
                        :rules="[{required: true}]">
                        <el-input v-model="form_data.local_port" placeholder="请输入本地监听端口" size="mini"  >
                        </el-input>
                    </el-form-item>

                    <el-form-item label="本地端口用户名" v-show="form_data.agreement == '104'"
                        :rules="[{required: true}]">
                        <el-input v-model="form_data.local_name" placeholder="请输入本地端口用户名" size="mini"  >
                        </el-input>
                    </el-form-item>

                    <el-form-item label="本地端口密码" v-show="form_data.agreement == '104'"
                        :rules="[{required: true}]">
                        <el-input v-model="form_data.local_passowrd" placeholder="请输入本地端口密码" size="mini"  >
                        </el-input>
                    </el-form-item>


                    <el-form-item label="移动售价" v-show="this.prop_item.type == 'add' && form_data.oper_array.indexOf('0') > -1">
                        <el-input v-model="form_data.CM_price" placeholder="请输入移动售价" size="mini" oninput="value=value.replace(/[^\d.]/g,'')" style="vertical-align: middle;">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="联通售价" v-show="this.prop_item.type == 'add' && form_data.oper_array.indexOf('1') > -1 ">
                        <el-input v-model="form_data.CU_price" placeholder="请输入联通售价" size="mini" oninput="value=value.replace(/[^\d.]/g,'')" style="vertical-align: middle;">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="电信售价" v-show="this.prop_item.type == 'add'  && form_data.oper_array.indexOf('2') > -1 ">
                        <el-input v-model="form_data.CT_price" placeholder="请输入电信售价" size="mini" oninput="value=value.replace(/[^\d.]/g,'')" style="vertical-align: middle;">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>

                    

                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
        </el-row>
    </el-dialog>

    <!-- 语音设置 -->
    <el-dialog class="voice_class" title="语音设置" center :visible.sync="dialog_visible_voice" destroy-on-close :show-close="true" width="98%" :modal-append-to-body="false">
        <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;">
            <el-form  label-width="0px" style="margin-bottom: 12px;">
                <template v-for="(item,index) in form_voice_data">
                    <el-form-item :key="index" v-if="item.two != '-1'" label="">
                        <el-input v-model="item.one" placeholder="" :disabled="index==0" style="width:40px;" size="mini" oninput="value=value.replace(/[^\d]/g,'')">
                        </el-input>
                        秒至
                        <el-input v-model="item.two" placeholder="" style="width:40px;" size="mini" oninput="value=value.replace(/[^\d]/g,'')">
                        </el-input>
                        秒,计费
                        <el-input v-model="item.three" placeholder="" style="width:40px;" size="mini" oninput="value=value.replace(/[^\d]/g,'')">
                        </el-input>
                        条
                        <el-button  type="text" size="mini" style="" @click="voice_add(index)">增加</el-button>
                        <el-button v-if="index !=0" type="text" size="mini" style="margin-left:2px;color:#f56c6c" @click="voice_del(index)">删除</el-button>
                    </el-form-item>

                    <el-form-item :key="index" v-if="item.two == '-1'" label="">
                        以后每
                        <el-input v-model="item.one" placeholder="" :disabled="index==0" style="width:40px;" size="mini" oninput="value=value.replace(/[^\d]/g,'')">
                        </el-input>
                        秒,计费累加
                        <el-input v-model="item.three" placeholder="" style="width:40px;" size="mini" oninput="value=value.replace(/[^\d]/g,'')">
                        </el-input>
                        条
                    </el-form-item>

                </template>
                <el-form-item>
                    <el-button type="primary" @click="voice_save()" size="mini">保存</el-button>
                </el-form-item>
                
            </el-form>
        </el-row>
    </el-dialog>

       <!--省市选择 组件 -->
    <ProvCitySelect :prop_change_i="prop_prov_city_select_i"
        :prop_item="prop_prov_city_select_item"
        @result="provCitySelectResult"
    ></ProvCitySelect>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import ProvCitySelect from '../../components/prov_city/prov_city_select.vue';//客户百分比通道组组件


//import axios from "axios";
export default {
    components: {
        ProvCitySelect
    },
    props:{
        prop_change_i:0,
        prop_item:{},//公司对象
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
            
            //console.log(this.prop_item);

                if(this.prop_item.type === "upd"){//修改
                    this.cur_title = "通道的修改";
                    setTimeout(() => {
                        //获取省份列表数据
                        this.getProvList();

                        //获取签名库列表数据
                        this.getSignLibraryList();

                        //获取特殊标签数据
                        this.getSpecialLabelList();

                        //获取预警策略数据
                        this.getWarningList();

                        //获取收款和付款公司数据
                        this.getSelfAndChannelCompanyList();

                        //获取数据
                        this.getData();
                        
                    }, 1);
                }else{
                    this.cur_title = "通道的增加";
                    setTimeout(() => {
                        //获取省份列表数据
                        this.getProvList();

                        //获取签名库列表数据
                        this.getSignLibraryList();

                        //获取特殊标签数据
                        this.getSpecialLabelList();

                        //获取预警策略数据
                        this.getWarningList();

                        //获取收款和付款公司数据
                        this.getSelfAndChannelCompanyList();

                        var myurl = API.getInterfaceUrl();
                        if(myurl.indexOf(".hetatech.cn")>-1 || myurl.indexOf('112.124.1.196')>-1){//河滔
                            this.form_data.server_ip = "172.24.103.232";//网关ip
                        }

                    }, 1);
                }
            }
            
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见


            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            cur_title:"",//当前页面的标题头

            provList:[],//省份列表数据-请求接口获取
            signLibraryList:[],//签名库列表数据-请求接口获取
            special_labelList:[],//特殊标签数据-请求接口获取
            warningList:[],//预警策略数据-请求接口获取
            selfCompanyList:[],//付款公司数据-请求接口获取
            channelCompanyList:[],//收款公司数据-请求接口获取

            dialog_visible_voice:false,//语音设置是否可见
            form_voice_data:[//语音
                {
                   one:'1',
                   two:'6',
                   three:'1' 
                },
                {
                   one:'6',
                   two:'-1',
                   three:'1' 
                }
            ],

            //省市选择-弹出层
            prop_prov_city_select_i:0,//弹出框是否可见
            prop_prov_city_select_item:{},
            prop_prov_city_select_oper:0,//当时的运营商

            cm_prov_city_select_map : new Map(),//移动 选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
            cu_prov_city_select_map : new Map(),//联通 选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
            ct_prov_city_select_map : new Map(),//电信 选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}

            form_data:{
                channel_id:"",//通道id
                channel_name:"",//通道名
                oper:"0,1,2",//可发运营商,0:移动;1联通,2电信（多个用半角逗号隔开）
                oper_array:["0","1","2"],//可发运营商数组
                cm_province:"0",//移动可发省份，放的是省份id，多个用半角逗号隔开 0:全国
                cu_province:"0",//联通可发省份，放的是省份id，多个用半角逗号隔开 0:全国
                ct_province:"0",//电信可发省份，放的是省份id，多个用半角逗号隔开 0:全国
                cm_province_array:["0"],//移动可发省份数组
                cu_province_array:["0"],//移动可发省份数组
                ct_province_array:["0"],//移动可发省份数组
                channel_style:"0",//通道类型,0:短信通道,1:语音通道 2：多媒体短信通道 3:固话通道
                channel_voice:"",//语音阶梯价格，开始条数、结束条数、单价用逗号分隔，每个阶梯之间用；分隔
                channel_voice_str:"",//语音阶梯价格文本
                specialnum:"",//特服号码
                alarm:"1",//短信数据统计（通道短信预警），0不预警  1预警  10点半是否下发统计短信
                sign_position:"0",//签名位置,0:强制前置,1:强制后置,2:不管签名位置
                sign_library_id:"0",//签名库id,0:不验签名库,不可为空
                sign_ext_onoff:"2",//签名扩展类型,0:使用签名库扩展；1:使用签名库扩展+客户扩展；2:使用客户扩展
                sign_after:"1",//后报备签名,0:允许,1:不允许
                onoff:"1",//状态,0:关闭,1:打开
                change_oper_onoff:"1",//是否支持携号转网,0:不支持,1:支持
                phone_check:"1",//实时空号过滤 0：打开 1：关闭
                trust_onoff:"0",//信任通道开关,0:不信任;1:信任  目前用于成功库表的入库  必填  默认不信任
                send_type:"1,2,3,4",//通道发送短信的类型,1:验证码;2:行业;3:营销;4:四类。多个用半角逗号隔开
                send_type_array:["1","2","3","4"],//通道发送短信的类型-数组
                link_is:"0",//是否允许带链接,0:允许;1:不允许
                link_trust_level:"1",//带链接可发级别,0:可发信任的客户,1:可发不信任的客户
                isLong:"0",//是否支持长短信,0:支持;1:不支持
                isMo:"0",//是否支持上行,0:支持,1:不支持
                ext_max:"8",//支持的最大扩展位数，默认为8
                special_label:"",//支持的特殊标签,多个用半角逗号,隔开
                special_label_array:[],//支持的特殊标签数组
                special_label_type:"0",//特殊标签的作用范围  0：可发此特殊标签、1：仅能发此特殊标签
                supplier_type:"2",//供应商类别,0:直连;1:准直连:2:三方;3:厂商
                firm_type:"0",//厂商类别 0:小米 1：oppo，当供应商类别是厂商（即supplier_type=4）时显示
                number_type:"1",//码号类别,0:固定码号;1:通道组
                all_or_prov:"0",//通道下发地区类型,0:全网;1:省网（只做展示，不使用）
                is_connect:"0",//是否允许带联系方式,0:允许;1:不允许
                pure_english_onoff:"0",//是否支持纯英文,0:支持;1:不支持
                is_own:"0",//是否自选通道,0:是;1:不是
                active_onoff:"1",//活跃性开关,0:打开,1:关闭
                warning_id:"0",//预警策略类型，对应预警策略表id，0:不预警
                warning_name:"",//预警策略名
                channel_fee_type:"1",//付费类型，0:预付费类型  1后付费类型
                channel_fee:"0",//通道余额 ，单位元（如果是预付费需要设置）
                pay_style:"5",//结算方式，2.日结 3.周结 4.半月结 5.月结
                payment_company_id:"",//付款公司id，对应t_signing_company表
                collection_company_id:"",//收款公司id，对应t_channel_company表
                path_start:"/user/QXT_CMPP20_Mina_Mass_DB/bin/main.sh",//启动路径
                path_shutdown:"/user/QXT_CMPP20_Mina_Mass_DB/bin/shutdown.sh",//关闭路径
                server_ip:"172.23.230.116",//服务器ip（网关ip）
                net_work_onoff:"1",//网关开关  0关闭  1打开 2:自动关闭
                frequency:"",//频次限制,例如一天一次（只做展示，不使用）
                send_speed:"100",//通道发送速度（单位条/秒） 不可为空
                content:"",//备注（只做展示，不使用）
                agreement:"101",//协议类型,0:无协议；1:http；101:cmpp2.0 ；102:cmpp3.0；104:SGIP；105:SMGP
                net_work_act:"",//用户名
                net_work_pwd:"",//密码
                userId:"",//userId
                concurrent:"1",//并发数
                ext_order:"0",//扩展码截取顺序，0:从前往后截取；1:从后往前截取
                ext_num:"20",//扩展码截取位数（最长支持21位）
                log_Onoff:"0",//日志输出，0:不需要；1:需要
                BeginTime_1:"0",//验证码发送开始时间
                EndTime_1:"2400",//验证码发送结束时间
                BeginTime_2:"0",//行业发送开始时间
                EndTime_2:"2400",//行业发送结束时间
                BeginTime_3:"800",//营销发送开始时间
                EndTime_3:"2200",//营销发送结束时间
                BeginTime_4:"800",//四类发送开始时间
                EndTime_4:"2200",//四类发送结束时间
                send_start_hh_yzm:"0",//发送开始小时-验证码
                send_start_mm_yzm:"00",//发送开始分钟-验证码
                send_end_hh_yzm:"24",//发送结束小时-验证码
                send_end_mm_yzm:"00",//发送结束分钟-验证码
                send_start_hh_hy:"0",//发送开始小时-行业
                send_start_mm_hy:"00",//发送开始分钟-行业
                send_end_hh_hy:"24",//发送结束小时-行业
                send_end_mm_hy:"00",//发送结束分钟-行业
                send_start_hh_yx:"8",//发送开始小时-营销
                send_start_mm_yx:"00",//发送开始分钟-营销
                send_end_hh_yx:"22",//发送结束小时-营销
                send_end_mm_yx:"00",//发送结束分钟-营销
                send_start_hh_sl:"8",//发送开始小时-四类
                send_start_mm_sl:"00",//发送开始分钟-四类
                send_end_hh_sl:"22",//发送结束小时-四类
                send_end_mm_sl:"00",//发送结束分钟-四类
                Gateway_IP:"",//通道连接ip
                msg_Src:"",//企业代码默认与账户相同
                Gateway_Port:"7890",//通道连接端口
                delSign:"0",//去签名，0不需要；1需要
                buffer_time:"10",//发送缓冲速度单位毫秒
                heartbeat_interval:"30",//心跳包发送的间隔时间单位秒
                reconnect_interval:"30",//连接时间单位秒
                heartbeat_noresponseout:"10",//心跳包等待回包时间单位秒
                Service_Id:"",//业务类型
                SRC_ID:"",//扩展码
                local_port:"",//本地监听端口  仅限联通使用
                local_name:"",//本地监听端口使用的用户名  仅限联通使用
                local_passowrd:"",//本地监听端口使用的密码  仅限联通使用

                CM_price:"0",//移动售价 单位分
                CU_price:"0",//联通售价 单位分
                CT_price:"0",//电信售价 单位分

            }
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
            this.form_voice_data = this.$options.data().form_voice_data;//单个恢复初始化
            this.dialog_visible_voice = this.$options.data().dialog_visible_voice;//单个恢复初始化

            this.cm_prov_city_select_map = this.$options.data().cm_prov_city_select_map;//单个恢复初始化
            this.cu_prov_city_select_map = this.$options.data().cu_prov_city_select_map;//单个恢复初始化
            this.ct_prov_city_select_map = this.$options.data().ct_prov_city_select_map;//单个恢复初始化

        },
        //查询
        getData(){
            this.form_data.channel_id = this.prop_item.channel_id+"";//通道id
            this.form_data.channel_name = this.prop_item.channel_name+"";//通道名
            this.form_data.oper = this.prop_item.oper+"";//可发运营商,0:移动;1联通,2电信（多个用半角逗号隔开）
            this.form_data.cm_province = this.prop_item.cm_province+"";//移动可发省份，放的是省份id，多个用半角逗号隔开
            this.form_data.cu_province = this.prop_item.cu_province+"";//联通可发省份，放的是省份id，多个用半角逗号隔开
            this.form_data.ct_province = this.prop_item.ct_province+"";//电信可发省份，放的是省份id，多个用半角逗号隔开
            this.form_data.channel_style = this.prop_item.channel_style+"";//通道类型,0:短信通道,1:语音通道 2：多媒体短信通道 3:固话通道
            this.form_data.channel_voice = this.prop_item.channel_voice+"";//语音阶梯价格，开始条数、结束条数、单价用逗号分隔，每个阶梯之间用；分隔
            this.form_data.channel_voice_str = this.prop_item.channel_voice_str+"";//语音阶梯价格文本
            this.form_data.specialnum = this.prop_item.specialnum+"";//特服号码
            this.form_data.alarm = this.prop_item.alarm+"";//短信数据统计（通道短信预警），0不预警  1预警  10点半是否下发统计短信
            this.form_data.sign_position = this.prop_item.sign_position+"";//签名位置,0:强制前置,1:强制后置,2:不管签名位置
            this.form_data.sign_library_id = this.prop_item.sign_library_id+"";//签名库id,0:不验签名库,不可为空
            this.form_data.sign_ext_onoff = this.prop_item.sign_ext_onoff+"";//签名扩展类型,0:使用签名库扩展；1:使用签名库扩展+客户扩展；2:使用客户扩展
            this.form_data.sign_after = this.prop_item.sign_after+"";//后报备签名,0:允许,1:不允许
            this.form_data.onoff = this.prop_item.onoff+"";//状态,0:关闭,1:打开
            this.form_data.change_oper_onoff = this.prop_item.change_oper_onoff+"";//是否支持携号转网,0:不支持,1:支持
            this.form_data.phone_check = this.prop_item.phone_check+"",//实时空号过滤 0：打开 1：关闭
            this.form_data.trust_onoff = this.prop_item.trust_onoff+"";//信任通道开关,0:不信任;1:信任  目前用于成功库表的入库  必填  默认不信任
            this.form_data.send_type = this.prop_item.send_type+"";//通道发送短信的类型,1:验证码;2:行业通知;3:营销;4:四类。多个用半角逗号隔开
            this.form_data.link_is = this.prop_item.link_is+"";//是否允许带链接,0:允许;1:不允许
            this.form_data.link_trust_level = this.prop_item.link_trust_level+"";//带链接可发级别,0:可发信任的客户,1:可发不信任的客户
            this.form_data.isLong = this.prop_item.isLong+"";//是否支持长短信,0:支持;1:不支持
            this.form_data.isMo = this.prop_item.isMo+"";//是否支持上行,0:支持,1:不支持
            this.form_data.ext_max = this.prop_item.ext_max+"";//支持的最大扩展位数，默认为8
            this.form_data.special_label = this.prop_item.special_label+"";//支持的特殊标签,多个用半角逗号,隔开
            this.form_data.special_label_type = this.prop_item.special_label_type+"";//特殊标签的作用范围  0：可发此特殊标签、1：仅能发此特殊标签
            this.form_data.supplier_type = this.prop_item.supplier_type+"";//供应商类别,0:直连;1:准直连:2:三方;3:厂商
            this.form_data.firm_type = this.prop_item.firm_type+"";//厂商类别 0:小米 1：oppo，当供应商类别是厂商（即supplier_type=4）时显示
            this.form_data.number_type = this.prop_item.number_type+"";//码号类别,0:固定码号;1:通道组
            this.form_data.all_or_prov = this.prop_item.all_or_prov+"";//通道下发地区类型,0:全网;1:省网（只做展示，不使用）
            this.form_data.is_connect = this.prop_item.is_connect+"";//是否允许带联系方式,0:允许;1:不允许
            this.form_data.pure_english_onoff = this.prop_item.pure_english_onoff+"";//是否支持纯英文,0:支持;1:不支持
            this.form_data.is_own = this.prop_item.is_own+"";//是否自选通道,0:是;1:不是
            this.form_data.active_onoff = this.prop_item.active_onoff+"";//活跃性开关,0:打开,1:关闭
            this.form_data.warning_id = this.prop_item.warning_id+"";//预警策略类型，对应预警策略表id，0:不预警
            this.form_data.channel_fee_type = this.prop_item.channel_fee_type+"";//付费类型，0:预付费类型  1后付费类型
            this.form_data.channel_fee = this.prop_item.channel_fee+"";//通道余额 ，单位元（如果是预付费需要设置）
            this.form_data.pay_style = this.prop_item.pay_style+"";//结算方式，2.日结 3.周结 4.半月结 5.月结
            this.form_data.payment_company_id = this.prop_item.payment_company_id+"";//付款公司id，对应t_signing_company表
            this.form_data.payment_company = this.prop_item.payment_company+"";//付款公司
            this.form_data.collection_company_id = this.prop_item.collection_company_id+"";//收款公司id，对应t_channel_company表
            this.form_data.collection_company = this.prop_item.collection_company+"";//收款公司
            this.form_data.path_start = this.prop_item.path_start+"";//启动路径
            this.form_data.path_shutdown = this.prop_item.path_shutdown+"";//关闭路径
            this.form_data.server_ip = this.prop_item.server_ip+"";//服务器ip（网关ip）
            this.form_data.net_work_onoff = this.prop_item.net_work_onoff+"";//网关开关  0关闭  1打开 2:自动关闭
            this.form_data.frequency = this.prop_item.frequency+"";//频次限制,例如一天一次（只做展示，不使用）
            this.form_data.send_speed = this.prop_item.send_speed+"";//通道发送速度（单位条/秒） 不可为空
            this.form_data.content = this.prop_item.content+"";//备注（只做展示，不使用）
            this.form_data.agreement = this.prop_item.agreement+"";//协议类型,0:无协议；1:http；101:cmpp2.0 ；102:cmpp3.0；104:SGIP；105:SMGP
            this.form_data.net_work_act = this.prop_item.net_work_act+"";//用户名
            this.form_data.net_work_pwd = this.prop_item.net_work_pwd+"";//密码
            this.form_data.userId = this.prop_item.userId+"";//userId
            this.form_data.concurrent = this.prop_item.concurrent+"";//并发数
            this.form_data.ext_order = this.prop_item.ext_order+"";//扩展码截取顺序，0:从前往后截取；1:从后往前截取
            this.form_data.ext_num = this.prop_item.ext_num+"";//扩展码截取位数（最长支持21位）
            this.form_data.log_Onoff = this.prop_item.log_Onoff+"";//日志输出，0:不需要；1:需要
            this.form_data.BeginTime_1 = this.prop_item.BeginTime_1+"";//验证码发送开始时间
            this.form_data.EndTime_1 = this.prop_item.EndTime_1+"";//验证码发送结束时间
            this.form_data.BeginTime_2 = this.prop_item.BeginTime_2+"";//行业发送开始时间
            this.form_data.EndTime_2 = this.prop_item.EndTime_2+"";//行业发送结束时间
            this.form_data.BeginTime_3 = this.prop_item.BeginTime_3+"";//营销发送开始时间
            this.form_data.EndTime_3 = this.prop_item.EndTime_3+"";//营销发送结束时间
            this.form_data.BeginTime_4 = this.prop_item.BeginTime_4+"";//四类发送开始时间
            this.form_data.EndTime_4 = this.prop_item.EndTime_4+"";//四类发送结束时间
            this.form_data.Gateway_IP = this.prop_item.Gateway_IP+"";//通道连接ip
            this.form_data.msg_Src = this.prop_item.msg_Src+"";//企业代码默认与账户相同
            this.form_data.Gateway_Port = this.prop_item.Gateway_Port+"";//通道连接端口
            this.form_data.delSign = this.prop_item.delSign+"";//去签名，0不需要；1需要
            this.form_data.buffer_time = this.prop_item.buffer_time+"";//发送缓冲速度单位毫秒
            this.form_data.heartbeat_interval = this.prop_item.heartbeat_interval+"";//心跳包发送的间隔时间单位秒
            this.form_data.reconnect_interval = this.prop_item.reconnect_interval+"";//连接时间单位秒
            this.form_data.heartbeat_noresponseout = this.prop_item.heartbeat_noresponseout+"";//心跳包等待回包时间单位秒
            this.form_data.Service_Id = this.prop_item.Service_Id+"";//业务类型
            this.form_data.SRC_ID = this.prop_item.SRC_ID+"";//扩展码
            this.form_data.local_port = this.prop_item.local_port+"";//本地监听端口  仅限联通使用
            this.form_data.local_name = this.prop_item.local_name+"";//本地监听端口使用的用户名  仅限联通使用
            this.form_data.local_passowrd = this.prop_item.local_passowrd+"";//本地监听端口使用的密码  仅限联通使用


            this.form_data.oper_array = this.form_data.oper.split(",");//可发运营商数组
            this.form_data.send_type_array = this.form_data.send_type.split(",");
            if(this.form_data.special_label != ""){
                this.form_data.special_label_array = this.form_data.special_label.split(",");
            }

            this.form_data.cm_province_array = this.prop_item.cm_province.split(",");//移动可发省份数组
            this.form_data.cu_province_array = this.prop_item.cu_province.split(",");//联通可发省份数组
            this.form_data.ct_province_array = this.prop_item.ct_province.split(",");//联通可发省份数组

            for(var oper=0;oper<3;oper++){
                //可发省份的地市
                var send_citys;
                if(oper == 0){
                    send_citys = this.prop_item.cm_send_citys_array;//允许发送的城市，当允许发送的省份为全国时，该字段可为空 格式：[{"prov_id":"省份ID","prov_name":"省份名称","city_array":[{"city":"西安"},{"city":"铜川"}]}]
                }else if(oper == 1){
                    send_citys = this.prop_item.cu_send_citys_array;//允许发送的城市，当允许发送的省份为全国时，该字段可为空 格式：[{"prov_id":"省份ID","prov_name":"省份名称","city_array":[{"city":"西安"},{"city":"铜川"}]}]
                }else{
                    send_citys = this.prop_item.ct_send_citys_array;//允许发送的城市，当允许发送的省份为全国时，该字段可为空 格式：[{"prov_id":"省份ID","prov_name":"省份名称","city_array":[{"city":"西安"},{"city":"铜川"}]}]
                }
                for(var s=0;s<send_citys.length;s++){
                    var send_city_obj = send_citys[s];
                    var prov_id = send_city_obj.prov_id;
                    var prov_name = send_city_obj.prov_name;
                    var city_array = send_city_obj.city_array;

                    var citys = [];//示例 ["保定", "唐山"]
                    for(var s2=0;s2<city_array.length;s2++){
                        citys.push(city_array[s2].city);
                    }
                    var city_obj = {prov_name:prov_name,citys:citys};
                    if(oper == 0){
                        this.cm_prov_city_select_map.set(prov_id+'',city_obj);//选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
                    }else if(oper == 1){
                        this.cu_prov_city_select_map.set(prov_id+'',city_obj);//选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
                    }else{
                        this.ct_prov_city_select_map.set(prov_id+'',city_obj);//选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
                    }
                }
            }
          

            //语音设置
            this.form_data.channel_voice = this.prop_item.channel_voice;
            if(!API.isEmtry(this.form_data.channel_voice)){
                var channel_voice_array = this.form_data.channel_voice.split(";");

                this.form_voice_data = [];//语音数据数组
                for(var i=0;i<channel_voice_array.length;i++){
                    var obj = channel_voice_array[i];
                    var ObjArray = obj.split(",");
                    var one = ObjArray[0];
                    var two = ObjArray[1];
                    var three = ObjArray[2];
                    var form_voice_data_obj = {one:one,two:two,three:three};
                    this.form_voice_data.push(form_voice_data_obj);
                }
            }


            //可发时段
            this.form_data.send_start_hh_yzm = this.send_hhmm_to(this.form_data.BeginTime_1,0);
            this.form_data.send_start_mm_yzm = this.send_hhmm_to(this.form_data.BeginTime_1,1);
            this.form_data.send_end_hh_yzm = this.send_hhmm_to(this.form_data.EndTime_1,0);
            this.form_data.send_end_mm_yzm = this.send_hhmm_to(this.form_data.EndTime_1,1);

            this.form_data.send_start_hh_hy = this.send_hhmm_to(this.form_data.BeginTime_2,0);
            this.form_data.send_start_mm_hy = this.send_hhmm_to(this.form_data.BeginTime_2,1);
            this.form_data.send_end_hh_hy = this.send_hhmm_to(this.form_data.EndTime_2,0);
            this.form_data.send_end_mm_hy = this.send_hhmm_to(this.form_data.EndTime_2,1);

            this.form_data.send_start_hh_yx = this.send_hhmm_to(this.form_data.BeginTime_3,0);
            this.form_data.send_start_mm_yx = this.send_hhmm_to(this.form_data.BeginTime_3,1);
            this.form_data.send_end_hh_yx = this.send_hhmm_to(this.form_data.EndTime_3,0);
            this.form_data.send_end_mm_yx = this.send_hhmm_to(this.form_data.EndTime_3,1);

            this.form_data.send_start_hh_sl = this.send_hhmm_to(this.form_data.BeginTime_4,0);
            this.form_data.send_start_mm_sl = this.send_hhmm_to(this.form_data.BeginTime_4,1);
            this.form_data.send_end_hh_sl = this.send_hhmm_to(this.form_data.EndTime_4,0);
            this.form_data.send_end_mm_sl = this.send_hhmm_to(this.form_data.EndTime_4,1);




        },
        
        //获取签名库列表数据
        getSignLibraryList(){
            //请求接口
            API.SignLibraryServlet({
                param: "list"
            }).then((res) => {
                if (res.recode === 0) {
                    this.signLibraryList = res.list;
                    var obj = {sign_library_id: 0, sign_name: "不验签名库" };
                    this.signLibraryList.unshift(obj);
                }
            });
        },

        //获取特殊标签数据
        getSpecialLabelList(){
            //请求接口
            API.LabelServlet({
                param: "specialAllList"
            }).then((res) => {
                if (res.recode === 0) {
                    this.special_labelList = res.list;//特殊标签数据-请求接口获取
                }
            });
        },

        //获取预警策略数据
        getWarningList(){
            //请求接口
            API.ControlStrategyServlet({
                param: "list"
            }).then((res) => {
                if (res.recode === 0) {
                    this.warningList = res.list;//预警策略数据-请求接口获取
                    var obj = {id: 0, name: "不配置策略" };
                    this.warningList.unshift(obj);
                }
            });
        },
        
        //获取省份列表数据
        getProvList(){
            //请求接口
            API.ProvinceServlet({
                param: "getProv"
            }).then((res) => {
                if (res.recode === 0) {
                    this.provList = res.provinceList;//省份列表数据-请求接口获取
                    var obj = {id: 0, province: "全国" };
                    this.provList.unshift(obj);

                }
            });
        },
        //省份改变调用方法
        provinceChange(event,oper, id){
            console.log("省份改变调用方法:省份id="+id+",oper:"+oper);
            // 如果是选中
            if (event) {
                // 把选中的id存入数组
                if(id == 0){//选中了全国

                    if(oper == 0){
                        this.form_data.cm_province_array = ["0"];
                        this.cm_prov_city_select_map.clear();//选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
                    }else if(oper == 1){
                        this.form_data.cu_province_array = ["0"];
                        this.cu_prov_city_select_map.clear();//选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
                    }else if(oper == 2){
                        this.form_data.ct_province_array = ["0"];
                        this.ct_prov_city_select_map.clear();//选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
                    }
                }else{//未选中

                    if(oper == 0){
                        var index = this.form_data.cm_province_array.indexOf("0");
                        if(index >-1 ){
                            this.form_data.cm_province_array.splice(index, 1); //删除全国
                        }
                    }else if(oper == 1){
                        var index = this.form_data.cu_province_array.indexOf("0");
                        if(index >-1 ){
                            this.form_data.cu_province_array.splice(index, 1); //删除全国
                        }
                    }else if(oper == 2){
                        var index = this.form_data.ct_province_array.indexOf("0");
                        if(index >-1 ){
                            this.form_data.ct_province_array.splice(index, 1); //删除全国
                        }
                    }
                }
            } 
            // console.log("移动省份:省份id="+id,this.form_data.cm_province_array);
            // console.log("联通省份:省份id="+id,this.form_data.cu_province_array);
            // console.log("电信省份:省份id="+id,this.form_data.ct_province_array);
        },

        //选择城市
        selectCity(prov_id,prov_name,oper){
            console.log("选择城市方法:prov_id="+prov_id+",prov_name="+prov_name+",oper="+oper);
            if(prov_id == 0 || prov_id == 1 || prov_id == 4 || prov_id == 7 || prov_id == 15){//1：上海 4：北京 7:天津 15：未知
                return;
            }

            var prov_city_obj;
            if(this.prop_prov_city_select_oper == 0){//移动
                prov_city_obj = this.cm_prov_city_select_map.get(prov_id+'');
            }else if(this.prop_prov_city_select_oper == 1){//联通
                prov_city_obj = this.cu_prov_city_select_map.get(prov_id+'');
            }else{//电信
                prov_city_obj = this.ct_prov_city_select_map.get(prov_id+'');
            }

            if(prov_city_obj != null){
                this.prop_prov_city_select_item.citys = prov_city_obj.citys; // 示例：['保定','石家庄']
            }else{
                this.prop_prov_city_select_item.citys = [];
            }


            //省市选择-弹出层
            this.prop_prov_city_select_item.prov_id = prov_id;
            this.prop_prov_city_select_item.prov_name = prov_name;
            this.prop_prov_city_select_oper = oper;//当时的运营商
            this.prop_prov_city_select_i ++;//弹出框是否可见
        },
        provCitySelectResult(prov_id,prov_name,citys){
            console.log("选择城市结果方法prov_id="+prov_id+",prov_name="+prov_name+",citys:",citys);
            var cityObj = {prov_name:prov_name,citys:citys};
            if(citys.length == 0){
                if(this.prop_prov_city_select_oper == 0){//移动
                    this.cm_prov_city_select_map.delete(prov_id+'');
                }else if(this.prop_prov_city_select_oper == 1){//联通
                    this.cu_prov_city_select_map.delete(prov_id+'');
                }else{//电信
                    this.ct_prov_city_select_map.delete(prov_id+'');
                }
            }else{
                if(this.prop_prov_city_select_oper == 0){//移动
                    this.cm_prov_city_select_map.set(prov_id+'',cityObj);//选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
                }else if(this.prop_prov_city_select_oper == 1){//联通
                    this.cu_prov_city_select_map.set(prov_id+'',cityObj);//选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
                }else{//电信
                    this.ct_prov_city_select_map.set(prov_id+'',cityObj);//选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
                }
            }

            if(this.prop_prov_city_select_oper == 0){//移动
                if(this.form_data.cm_province == "0"){//选择的是全国的话,把值置空
                    this.form_data.cm_province = "";
                    this.form_data.cm_province_array = [];
                }else {
                    var send_pros = this.form_data.cm_province.split(",");

                    var send_pro_new = "";
                    for(var i = 0;i<send_pros.length;i++){
                        var one = send_pros[i];
                        if(one != prov_id+""){
                            if(send_pro_new == ""){
                                send_pro_new = one+"";
                            }else{
                                send_pro_new += ","+one;
                            }
                        }
                    }
                    this.form_data.cm_province = send_pro_new;
                }
                console.log("this.form_data.cm_province:"+this.form_data.cm_province);
                //console.log("map长度：",this.cm_prov_city_select_map.size);

            }else if(this.prop_prov_city_select_oper == 1){//联通
                if(this.form_data.cu_province == "0"){//选择的是全国的话,把值置空
                    this.form_data.cu_province = "";
                    this.form_data.cu_province_array = [];
                }else {
                    var send_pros = this.form_data.cu_province.split(",");

                    var send_pro_new = "";
                    for(var i = 0;i<send_pros.length;i++){
                        var one = send_pros[i];
                        if(one != prov_id+""){
                            if(send_pro_new == ""){
                                send_pro_new = one+"";
                            }else{
                                send_pro_new += ","+one;
                            }
                        }
                    }
                    this.form_data.cu_province = send_pro_new;
                }
                console.log("this.form_data.cu_province:"+this.form_data.cu_province);
            }else{//电信
                if(this.form_data.ct_province == "0"){//选择的是全国的话,把值置空
                    this.form_data.ct_province = "";
                    this.form_data.ct_province_array = [];
                }else {
                    var send_pros = this.form_data.ct_province.split(",");

                    var send_pro_new = "";
                    for(var i = 0;i<send_pros.length;i++){
                        var one = send_pros[i];
                        if(one != prov_id+""){
                            if(send_pro_new == ""){
                                send_pro_new = one+"";
                            }else{
                                send_pro_new += ","+one;
                            }
                        }
                    }
                    this.form_data.ct_province = send_pro_new;
                }
                console.log("this.form_data.ct_province:"+this.form_data.ct_province);
            }

           //console.log("是否包含：",this.prov_city_select_map.get(prov_id));

            this.$forceUpdate();//重新渲染vue
            

            // this.prov_city_select_map.forEach((value, key) => {
            //     console.log( "key:"+key," value:",value)
            // })
        },

        //获取收款和付款公司数据
        getSelfAndChannelCompanyList(){
            //请求接口
            API.ChannelCompanyServlet({
                param: "companyList"
            }).then((res) => {
                if (res.recode === 0) {
                    this.selfCompanyList = res.paymentList;//付款公司数据-请求接口获取
                    this.channelCompanyList = res.companyList//收款公司数据-请求接口获取

                }
            });
        },
     
        //语音设置
        voice_set(){
            this.dialog_visible_voice = true;//语音设置是否可见
        },
        //语音设置-删除
        voice_del(index){
            this.form_voice_data.splice(index,1);
        },
        //语音设置-增加
        voice_add(index){
            var obj = this.form_voice_data[index];
            var one = (parseInt(obj.two)+1)+"";
            var two = (parseInt(one)+5)+"";
            var three = (parseInt(obj.three)+1)+"";

            var obj = {one:one,two:two,three:three}
            this.form_voice_data.splice(index+1,0,obj);
        },
        //语音设置-保存
        voice_save(){
            var channel_voice = "";//语音阶梯价格，开始条数、结束条数、单价用逗号分隔，每个阶梯之间用；分隔
            var channel_voice_str = "";

            var one_before = "0";
            var two_before = "0";
            var three_before = "0";
            for(var i=0;i<this.form_voice_data.length;i++){
                var obj = this.form_voice_data[i];
                var one = obj.one;
                var two = obj.two;
                var three = obj.three;

                

                if(two != '-1'){
                    if(API.isEmtry(one)){
                        this.$message.error("请填写第"+(i+1)+"行的开始秒数");
                        return;
                    }
                    if(API.isEmtry(two)){
                        this.$message.error("请填写第"+(i+1)+"行的结束秒数");
                        return;
                    }

                    if(parseInt(one) < 1){
                        this.$message.error("第"+(i+1)+"行的开始秒数不能低于1");
                        return;
                    }
                    if(parseInt(two) < 1){
                        this.$message.error("第"+(i+1)+"行的开始秒数不能低于1");
                        return;
                    }
                }else{
                    if(API.isEmtry(one)){
                        this.$message.error("请填写最后一行的间隔秒数");
                        return;
                    }
                    if(parseInt(one) < 1){
                        this.$message.error("最后一行行的间隔秒数不能低于1");
                        return;
                    }
                }
                if(API.isEmtry(three)){
                    this.$message.error("请填写第"+(i+1)+"行的计费条数");
                    return;
                }
                if(parseInt(three) < 0){
                    this.$message.error("第"+(i+1)+"行的计费条数不能低于0");
                    return;
                }
                
                if(i > 0){
                    if(two != '-1' && parseInt(one) != parseInt(two_before) +1 ){
                        this.$message.error("第"+(i+1)+"行的开始秒数与上一行的结束秒数不连贯");
                        return;
                    }
                    //console.log("i:"+i+" one:"+one+" two:"+two);
                    if(two != '-1' && parseInt(two) <= parseInt(one)){
                        this.$message.error("第"+(i+1)+"行的结束秒数需大于"+one+"秒");
                        return;
                    }

                    if(two != '-1'){
                        if(parseInt(three) < parseInt(three_before)){
                            this.$message.error("第"+(i+1)+"行的计费条数需大于或等于"+three_before+"条");
                            return;
                        }
                    }

                    
                }else{
                    if(parseInt(two) <= parseInt(one)){
                        this.$message.error("第"+(i+1)+"行的结束秒数需大于"+one+"秒");
                        return;
                    }

                }

                if(channel_voice != ""){
                    channel_voice += ";";
                }
                if(two == '-1'){
                    channel_voice_str += "以后每"+one+"秒，计费累加"+three+"条;";

                    channel_voice += one+","+two+","+three;
                }else{
                    channel_voice_str += one+"秒至"+two+"秒内，计费"+three+"条;";

                    channel_voice += one+","+two+","+three;
                }

                one_before = one;
                two_before = two;
                three_before = three;

            }
            this.form_data.channel_voice = channel_voice;
            this.form_data.channel_voice_str = channel_voice_str;
            this.dialog_visible_voice = false;//语音设置是否可见

        },

        //提交
        onSubmit(){
            console.log("提交");

            if(API.isEmtry(this.form_data.channel_name)){
                this.$message.error("请填写通道名");
                return;
            }

            if(this.form_data.oper_array.length == 0){
                this.$message.error("请选择可发的运营商");
                return;
            }else{
                this.form_data.oper = this.form_data.oper_array.toString();
            }

            if(this.form_data.oper_array.indexOf("0") > -1){
                if(this.form_data.cm_province_array.length == 0 && this.cm_prov_city_select_map.size == 0){
                    this.$message.error("请选择移动可发省份或地市");
                    return;
                }else{
                    this.form_data.cm_province = this.form_data.cm_province_array.toString();
                }
            }else{
                this.form_data.cm_province = "";
            }

            if(this.form_data.oper_array.indexOf("1") > -1){
                if(this.form_data.cu_province_array.length == 0  && this.cu_prov_city_select_map.size == 0){
                    this.$message.error("请选择联通可发省份或地市");
                    return;
                }else{
                    this.form_data.cu_province = this.form_data.cu_province_array.toString();
                }
            }else{
                this.form_data.cu_province = "";
            }

            if(this.form_data.oper_array.indexOf("2") > -1){
                if(this.form_data.ct_province_array.length == 0  && this.ct_prov_city_select_map.size == 0){
                    this.$message.error("请选择电信可发省份或地市");
                    return;
                }else{
                    this.form_data.ct_province = this.form_data.ct_province_array.toString();
                }
            }else{
                this.form_data.ct_province = "";
            }
            

            if(this.form_data.channel_style == "1"){//通道类型,0:短信通道,1:语音通道 2：多媒体短信通道 3:固话通道
                if(API.isEmtry(this.form_data.channel_voice)){
                    this.$message.error("请设置语音价格");
                    return;
                }
            }

            if(this.form_data.send_type_array.length == 0){
                this.$message.error("请选择下发短信类型");
                return;
            }else{
                this.form_data.send_type = this.form_data.send_type_array.toString();
            }

            if(this.form_data.special_label_array.length > 0){//特殊标签
                this.form_data.special_label = this.form_data.special_label_array.toString();
                
            }else{
                this.form_data.special_label = "";
                this.form_data.special_label_type = "0";//特殊标签的作用范围  0：可发此特殊标签、1：仅能发此特殊标签
            }

            if(this.form_data.channel_fee_type == '0'){//预付
                if(API.isEmtry(this.form_data.channel_fee)){
                    this.$message.error("请填写通道余额");
                    return;
                }
                this.form_data.pay_style = '0';//结算方式 2.日结 3.周结 4.半月结 5.月结
            }else{//后付
                this.form_data.channel_fee = '0';//通道余额  单位元
            }

            if(API.isEmtry(this.form_data.payment_company_id)){
                this.$message.error("请选择付款公司");
                return;
            }
            if(API.isEmtry(this.form_data.collection_company_id)){
                this.$message.error("请选择收款公司");
                return;
            }
            if(API.isEmtry(this.form_data.send_speed)){
                this.$message.error("请填写发送速度");
                return;
            }

            if(API.isEmtry(this.form_data.net_work_act)){
                this.$message.error("请填写用户名");
                return;
            }
            if(API.isEmtry(this.form_data.net_work_pwd)){
                this.$message.error("请填写密码");
                return;
            }

            if(this.form_data.agreement == '1' && API.isEmtry(this.form_data.userId)){
                this.$message.error("请填写userId");
                return;
            }

            if(API.isEmtry(this.form_data.ext_num)){
                this.$message.error("请填写扩展码截取位数");
                return;
            }else{
                if(this.form_data.ext_num > 21){
                    this.$message.error("扩展码截取位数不能超过21位");
                }
            }


            if(API.isEmtry(this.form_data.send_start_hh_yzm)){
                this.$message.error("请填写验证码可发时段的起始时间");
                return;
            }
            if(API.isEmtry(this.form_data.send_end_hh_yzm)){
                this.$message.error("请填写验证码可发时段的结束时间");
                return;
            }
            if(API.isEmtry(this.form_data.send_start_hh_hy)){
                this.$message.error("请填写行业可发时段的起始时间");
                return;
            }
            if(API.isEmtry(this.form_data.send_end_hh_hy)){
                this.$message.error("请填写行业可发时段的结束时间");
                return;
            }
            if(API.isEmtry(this.form_data.send_start_hh_yx)){
                this.$message.error("请填写营销可发时段的起始时间");
                return;
            }
            if(API.isEmtry(this.form_data.send_end_hh_yx)){
                this.$message.error("请填写营销可发时段的结束时间");
                return;
            }
            if(API.isEmtry(this.form_data.send_start_hh_sl)){
                this.$message.error("请填写四类可发时段的起始时间");
                return;
            }
            if(API.isEmtry(this.form_data.send_end_hh_sl)){
                this.$message.error("请填写四类可发时段的结束时间");
                return;
            }
            this.form_data.BeginTime_1 = parseInt(this.form_data.send_start_hh_yzm+""+this.form_data.send_start_mm_yzm)+"";
            this.form_data.EndTime_1 = parseInt(this.form_data.send_end_hh_yzm+""+this.form_data.send_end_mm_yzm)+"";
            this.form_data.BeginTime_2 = parseInt(this.form_data.send_start_hh_hy+""+this.form_data.send_start_mm_hy)+"";
            this.form_data.EndTime_2 = parseInt(this.form_data.send_end_hh_hy+""+this.form_data.send_end_mm_hy)+"";
            this.form_data.BeginTime_3 = parseInt(this.form_data.send_start_hh_yx+""+this.form_data.send_start_mm_yx)+"";
            this.form_data.EndTime_3= parseInt(this.form_data.send_end_hh_yx+""+this.form_data.send_end_mm_yx)+"";
            this.form_data.BeginTime_4 = parseInt(this.form_data.send_start_hh_sl+""+this.form_data.send_start_mm_sl)+"";
            this.form_data.EndTime_4 = parseInt(this.form_data.send_end_hh_sl+""+this.form_data.send_end_mm_sl)+"";


            if(this.form_data.agreement != '0' && this.form_data.agreement != '1'){
                if(API.isEmtry(this.form_data.Gateway_IP)){
                    this.$message.error("请填写链接ip");
                    return;
                }

                if(API.isEmtry(this.form_data.msg_Src)){
                    this.$message.error("请填写企业代码(msg_src)");
                    return;
                }

                if(API.isEmtry(this.form_data.Gateway_Port)){
                    this.$message.error("请填写链接端口");
                    return;
                }

                if(API.isEmtry(this.form_data.buffer_time)){
                    this.$message.error("请填写发送缓冲");
                    return;
                }

                if(API.isEmtry(this.form_data.heartbeat_interval)){
                    this.$message.error("请填写心跳包间隔发送时间");
                    return;
                }

                if(API.isEmtry(this.form_data.reconnect_interval)){
                    this.$message.error("请填写链接运营商超时阈值");
                    return;
                }

                if(API.isEmtry(this.form_data.heartbeat_noresponseout)){
                    this.$message.error("请填写心跳包等待最长时间");
                    return;
                }
            }

            if(this.form_data.agreement == '104'){
                if(API.isEmtry(this.form_data.local_port)){
                    this.$message.error("请填写本地监听端口");
                    return;
                }
                if(API.isEmtry(this.form_data.local_name)){
                    this.$message.error("请填写本地端口用户名");
                    return;
                }
                if(API.isEmtry(this.form_data.local_passowrd)){
                    this.$message.error("请填写本地端口密码");
                    return;
                }
                
            }

            // console.log("this.form_data.send_end_hh_sl:"+this.form_data.send_end_hh_sl);
            // console.log("this.form_data.EndTime_4:"+this.form_data.EndTime_4);

            if(this.prop_item.type == 'add' && this.form_data.oper_array.indexOf('0') > -1){
                if(API.isEmtry(this.form_data.CM_price)){
                    this.$message.error("请填写移动售价");
                    return;
                }else if(parseFloat(this.form_data.CM_price) <= 0){
                    this.$message.error("移动售价需大于0");
                    return;
                }
            }

            if(this.prop_item.type == 'add' && this.form_data.oper_array.indexOf('1') > -1){
                if(API.isEmtry(this.form_data.CU_price)){
                    this.$message.error("请填写联通售价");
                    return;
                }else if(parseFloat(this.form_data.CU_price) <= 0){
                    this.$message.error("联通售价需大于0");
                    return;
                }
            }

            if(this.prop_item.type == 'add' && this.form_data.oper_array.indexOf('2') > -1){
                if(API.isEmtry(this.form_data.CT_price)){
                    this.$message.error("请填写电信售价");
                    return;
                }else if(parseFloat(this.form_data.CT_price) <= 0){
                    this.$message.error("电信售价需大于0");
                    return;
                }
            }
            

            var submit_obj = {};
            if(this.prop_item.type === "upd"){//修改
                submit_obj.param = "upd";
                submit_obj.channel_id = this.form_data.channel_id;//通道id
            }else{//增加
                submit_obj.param = "add";
                submit_obj.CM_price = this.form_data.CM_price;//移动售价
                submit_obj.CU_price = this.form_data.CU_price;//联通售价
                submit_obj.CT_price = this.form_data.CT_price;//电信售价

            }
            submit_obj.channel_name = this.form_data.channel_name;//通道名
            submit_obj.oper = this.form_data.oper;//可发运营商,0:移动;1联通,2电信（多个用半角逗号隔开）
            submit_obj.cm_province = this.form_data.cm_province;//移动可发省份，放的是省份id，多个用半角逗号隔开
            submit_obj.cu_province = this.form_data.cu_province;//联通可发省份，放的是省份id，多个用半角逗号隔开
            submit_obj.ct_province = this.form_data.ct_province;//电信可发省份，放的是省份id，多个用半角逗号隔开
            submit_obj.channel_style = this.form_data.channel_style;//通道类型,0:短信通道,1:语音通道 2：多媒体短信通道 3:固话通道
            submit_obj.channel_voice = this.form_data.channel_voice;//语音阶梯价格，开始条数、结束条数、单价用逗号分隔，每个阶梯之间用；分隔
            submit_obj.specialnum = this.form_data.specialnum;//特服号码
            submit_obj.alarm = this.form_data.alarm;//短信数据统计（通道短信预警），0不预警  1预警  10点半是否下发统计短信
            submit_obj.sign_position = this.form_data.sign_position;//签名位置,0:强制前置,1:强制后置,2:不管签名位置
            submit_obj.sign_library_id = this.form_data.sign_library_id;//签名库id,0:不验签名库,不可为空
            submit_obj.sign_ext_onoff = this.form_data.sign_ext_onoff;//签名扩展类型,0:使用签名库扩展；1:使用签名库扩展+客户扩展；2:使用客户扩展
            submit_obj.sign_after = this.form_data.sign_after;//后报备签名,0:允许,1:不允许
            submit_obj.onoff = this.form_data.onoff;//状态,0:关闭,1:打开
            submit_obj.change_oper_onoff = this.form_data.change_oper_onoff;//是否支持携号转网,0:不支持,1:支持
            submit_obj.phone_check = this.form_data.phone_check,//实时空号过滤 0：打开 1：关闭
            submit_obj.trust_onoff = this.form_data.trust_onoff;//信任通道开关,0:不信任;1:信任  目前用于成功库表的入库  必填  默认不信任
            submit_obj.send_type = this.form_data.send_type;//通道发送短信的类型,1:验证码;2:行业通知;3:营销;4:四类。多个用半角逗号隔开
            submit_obj.link_is = this.form_data.link_is;//是否允许带链接,0:允许;1:不允许
            submit_obj.link_trust_level = this.form_data.link_trust_level;//带链接可发级别,0:可发信任的客户,1:可发不信任的客户
            submit_obj.isLong = this.form_data.isLong;//是否支持长短信,0:支持;1:不支持
            submit_obj.isMo = this.form_data.isMo;//是否支持上行,0:支持,1:不支持
            submit_obj.ext_max = this.form_data.ext_max;//支持的最大扩展位数，默认为8
            submit_obj.special_label = this.form_data.special_label;//支持的特殊标签,多个用半角逗号,隔开
            submit_obj.special_label_type = this.form_data.special_label_type;//特殊标签的作用范围  0：可发此特殊标签、1：仅能发此特殊标签
            submit_obj.supplier_type = this.form_data.supplier_type;//供应商类别,0:直连;1:准直连:2:三方;3:厂商
            submit_obj.firm_type = this.form_data.firm_type;//厂商类别 0:小米 1：oppo，当供应商类别是厂商（即supplier_type=4）时显示
            submit_obj.number_type = this.form_data.number_type;//码号类别,0:固定码号;1:通道组
            submit_obj.all_or_prov = this.form_data.all_or_prov;//通道下发地区类型,0:全网;1:省网（只做展示，不使用）
            submit_obj.is_connect = this.form_data.is_connect;//是否允许带联系方式,0:允许;1:不允许
            submit_obj.pure_english_onoff = this.form_data.pure_english_onoff;//是否支持纯英文,0:支持;1:不支持
            submit_obj.is_own = this.form_data.is_own;//是否自选通道,0:是;1:不是
            submit_obj.active_onoff = this.form_data.active_onoff;//活跃性开关,0:打开,1:关闭
            submit_obj.warning_id = this.form_data.warning_id;//预警策略类型，对应预警策略表id，0:不预警
            submit_obj.channel_fee_type = this.form_data.channel_fee_type;//付费类型，0:预付费类型  1后付费类型
            submit_obj.channel_fee = this.form_data.channel_fee;//通道余额 ，单位元（如果是预付费需要设置）
            submit_obj.pay_style = this.form_data.pay_style;//结算方式，2.日结 3.周结 4.半月结 5.月结
            submit_obj.payment_company_id = this.form_data.payment_company_id;//付款公司id，对应t_signing_company表
            submit_obj.payment_company = this.form_data.payment_company;//付款公司
            submit_obj.collection_company_id = this.form_data.collection_company_id;//收款公司id，对应t_channel_company表
            submit_obj.collection_company = this.form_data.collection_company;//收款公司
            submit_obj.path_start = this.form_data.path_start;//启动路径
            submit_obj.path_shutdown = this.form_data.path_shutdown;//关闭路径
            submit_obj.server_ip = this.form_data.server_ip;//服务器ip（网关ip）
            submit_obj.net_work_onoff = this.form_data.net_work_onoff;//网关开关  0关闭  1打开 2:自动关闭
            submit_obj.frequency = this.form_data.frequency;//频次限制,例如一天一次（只做展示，不使用）
            submit_obj.send_speed = this.form_data.send_speed;//通道发送速度（单位条/秒） 不可为空
            submit_obj.content = this.form_data.content;//备注（只做展示，不使用）
            submit_obj.agreement = this.form_data.agreement;//协议类型,0:无协议；1:http；101:cmpp2.0 ；102:cmpp3.0；104:SGIP；105:SMGP
            submit_obj.net_work_act = this.form_data.net_work_act;//用户名
            submit_obj.net_work_pwd = this.form_data.net_work_pwd;//密码
            submit_obj.userId = this.form_data.userId;//userId
            submit_obj.concurrent = this.form_data.concurrent;//并发数
            submit_obj.ext_order = this.form_data.ext_order;//扩展码截取顺序，0:从前往后截取；1:从后往前截取
            submit_obj.ext_num = this.form_data.ext_num;//扩展码截取位数（最长支持21位）
            submit_obj.log_Onoff = this.form_data.log_Onoff;//日志输出，0:不需要；1:需要
            submit_obj.BeginTime_1 = this.form_data.BeginTime_1;//验证码发送开始时间
            submit_obj.EndTime_1 = this.form_data.EndTime_1;//验证码发送结束时间
            submit_obj.BeginTime_2 = this.form_data.BeginTime_2;//行业发送开始时间
            submit_obj.EndTime_2 = this.form_data.EndTime_2;//行业发送结束时间
            submit_obj.BeginTime_3 = this.form_data.BeginTime_3;//营销发送开始时间
            submit_obj.EndTime_3 = this.form_data.EndTime_3;//营销发送结束时间
            submit_obj.BeginTime_4 = this.form_data.BeginTime_4;//四类发送开始时间
            submit_obj.EndTime_4 = this.form_data.EndTime_4;//四类发送结束时间
            submit_obj.Gateway_IP = this.form_data.Gateway_IP;//通道连接ip
            submit_obj.msg_Src = this.form_data.msg_Src;//企业代码默认与账户相同
            submit_obj.Gateway_Port = this.form_data.Gateway_Port;//通道连接端口
            submit_obj.delSign = this.form_data.delSign;//去签名，0不需要；1需要
            submit_obj.buffer_time = this.form_data.buffer_time;//发送缓冲速度单位毫秒
            submit_obj.heartbeat_interval = this.form_data.heartbeat_interval;//心跳包发送的间隔时间单位秒
            submit_obj.reconnect_interval = this.form_data.reconnect_interval;//链接运营商超时阈值,单位秒
            submit_obj.heartbeat_noresponseout = this.form_data.heartbeat_noresponseout;//心跳包等待回包时间单位秒
            submit_obj.Service_Id = this.form_data.Service_Id;//业务类型
            submit_obj.SRC_ID = this.form_data.SRC_ID;//扩展码
            submit_obj.local_port = this.form_data.local_port;//本地监听端口  仅限联通使用
            submit_obj.local_name = this.form_data.local_name;//本地监听端口使用的用户名  仅限联通使用
            submit_obj.local_passowrd = this.form_data.local_passowrd;//本地监听端口使用的密码  仅限联通使用

            submit_obj.cm_send_citys = "";//移动允许发送的城市
            submit_obj.cu_send_citys = "";//联通允许发送的城市
            submit_obj.ct_send_citys = "";//电信允许发送的城市
            for(var oper=0;oper < 3;oper++){
                
                if(this.form_data.oper_array.indexOf(oper+"") > -1){
                    
                    var prov_city_select_map;
                    if(oper == 0){
                        prov_city_select_map = this.cm_prov_city_select_map;
                    }else if(oper == 1){
                        prov_city_select_map = this.cu_prov_city_select_map;
                    }else{
                        prov_city_select_map = this.ct_prov_city_select_map;
                    }
                    if(prov_city_select_map.size > 0){
                        var send_citys = [];
                        prov_city_select_map.forEach((value, key) => {//key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
                            var prov_id = key;
                            var citys = value.citys;
                            var city_array = [];
                            for(var i =0;i<citys.length;i++){
                                var city_obj = {city:citys[i]};
                                city_array.push(city_obj);
                            }

                            var obj = {prov_id:prov_id,city_array:city_array};
                            send_citys.push(obj);
                        });
                        if(oper == 0){
                            submit_obj.cm_send_citys = JSON.stringify(send_citys);
                        }else if(oper == 1){
                             submit_obj.cu_send_citys = JSON.stringify(send_citys);
                        }else{
                             submit_obj.ct_send_citys = JSON.stringify(send_citys);
                        }
                    }
                }
            }
            
            
            API.ChannelServlet(submit_obj).then((res) => {
                if (res.recode === 0) {
                    this.$message.success("恭喜您，信息保存成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },


        //hhmm转数字  type:0：获取小时  1：获取分钟
        send_hhmm_to(send_hhmm,type){
            var send_hhmm_str = "0";

            if(API.isEmtry(send_hhmm)){
                if(type == 1){
                    send_hhmm_str = "00";
                }
                return send_hhmm_str;
            }
            if(send_hhmm.length == 1){
                if(type == 0){
                    send_hhmm_str = "0";
                }else{
                    send_hhmm_str = "00";
                }
            }else if(send_hhmm.length == 2){
               if(type == 0){
                    send_hhmm_str = "0";
                }else{
                    send_hhmm_str = "30";
                }
            }else if(send_hhmm.length == 3){
                
                if(type == 0){
                    send_hhmm_str = send_hhmm.substring(0,1);
                }else{
                    var send_hhmm_end_2 = send_hhmm.substring(1);
                    if(send_hhmm_end_2 == '00'){
                        send_hhmm_str = "00";
                    }else{
                        send_hhmm_str = "30";
                    }
                }
                
            }else if(send_hhmm.length == 4){
                if(type == 0){
                    send_hhmm_str = send_hhmm.substring(0,2)
                }else{
                    var send_hhmm_end_2 = send_hhmm.substring(2);
                    if(send_hhmm_end_2 == '00'){
                        send_hhmm_str = "00";
                    }else{
                        send_hhmm_str = "30";
                    }
                }
            }
            return send_hhmm_str;
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 1px;
} 

/*语音表单输入框*/
.voice_class >>>.el-input__inner{
    padding: 0 5px;
}
</style>