import { render, staticRenderFns } from "./channel_add_upd.vue?vue&type=template&id=bab8062c&scoped=true&"
import script from "./channel_add_upd.vue?vue&type=script&lang=js&"
export * from "./channel_add_upd.vue?vue&type=script&lang=js&"
import style0 from "./channel_add_upd.vue?vue&type=style&index=0&id=bab8062c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bab8062c",
  null
  
)

export default component.exports